// Presets component, it manages presets and audio files. It makes user of preset_manager_settings and presets folder which contains json files with preset data and sounds folder which contains audio files. It provides Player component with current preset data, methods to change current preset and methods to manage presets and audio files
// preset_manager_settings.json is structured like this: {"current_preset": "Preset Name2", "presets": ["Preset Name1", "Preset Name2", "Preset Name3"], "presets_order": ["Preset Name2", "Preset Name3", "Preset Name1"]}
// Each preset is stored in separate json file in presets folder. It is structured like this: {"name": "Preset Name", "mute": false", "volume": 50, "sounds": [{"Name": {"mute": false, "volume": 50, "path": "sounds/Name.mp3"}}, {"Name2": {"mute": false, "volume": 50, "path": "sounds/Name2.mp3"}}]}
// It contains classes: PresetsManager, Preset. Presets manager is responsible for managing presets and Audio class from Audio.js. It has methods: add_preset, add_sound, add_sounds, change_preset_name, change_preset_order, get_current_preset, get_preset, load_presets, mute_current_preset, next_preset, previous_preset, remove_preset, remove_sound, set_current_preset, set_preset_volume, set_sound_mute, set_sound_volume
// Preset class is responsible for state of single preset. It has methods: add_sound, add_sounds, remove_sound, set_mute, set_sound_mute, set_sound_volume, set_volume.

// import Audio component from Audio.js
import Audio_Player from './Audio_Player';
// Put all presets in presets folder in local storage as dictionary of preset names without extension and content of json files. Replace underscores with spaces in preset names
// If there are no presets in local storage, load them from presets folder
if (localStorage.getItem('presets') === null) {
  const presets = require.context('./presets', true, /\.json$/);
  var presets_dict = {};
  presets.keys().forEach((key) => {
    var preset_name = key.replace('./', '').replace('.json', '').replace(/_/g, ' ');
    presets_dict[preset_name] = presets(key);
  });
  localStorage.setItem('presets', JSON.stringify(presets_dict));
}

if (localStorage.getItem('sounds') === null) {
  const sounds = require.context('./sounds', true);
  var sounds_dict = {};
  sounds.keys().forEach((key) => {
    // Get sound name from path, keep extension
    var sound_name = key.replace('./', '');
    sounds_dict[sound_name] = sounds(key);
  });
  localStorage.setItem('sounds', JSON.stringify(sounds_dict));
}
/*
Here's what those classes should work like:
Preset:
  Attributes:
    preset_settings: temporary storage of preset settings json file
    name: name of the preset
    volume: volume of the preset
    sounds: dictionary of sounds in the preset
    mute: mute state of the preset
    
  Methods:
      set_volume: set volume of the preset
      set_sound_volume: set volume of the sound
      set_sound_mute: set mute state of the sound
      add_sound: add sound to the preset
      remove_sound: remove sound from the preset
      set_mute: set mute state of the preset

PresetsManager:
  Attributes:
      presets_manager_settings: temporary storage of the presets manager settings
      presets: dict of all presets
      presets_order: list of all presets names in the right order
      current_preset: current preset object
      audio: audio object
      
  Methods:
      load_presets: load all presets from the settings file
      get_preset: get preset object by name
      get_current_preset: get current preset object
      set_current_preset: set current preset by name
      next_preset: set next preset
      previous_preset: set previous preset
      add_preset: add new preset
      remove_preset: remove preset
      change_preset_order: change preset order
      change_preset_name: change preset name
      set_preset_volume: set preset volume
      set_sound_volume: set sound volume
      set_sound_mute: set sound mute
      add_sound: add sound to current preset
      add_sounds: add sounds to current preset
      remove_sound: remove sound from current preset
      mute_current_preset: mute current preset
*/

class Preset {
  // preset_settings: json file with preset settings
  constructor(preset_settings) {
    this.preset_settings = preset_settings;
    this.name = preset_settings.name;
    this.volume = preset_settings.volume;
    this.sounds = preset_settings.sounds;
    this.mute = preset_settings.mute;
  }

  set_volume(volume) {
    this.volume = volume;
    this.preset_settings.volume = volume;
  }

  set_sound_volume(sound_name, volume) {
    this.sounds[sound_name].volume = volume;
    this.preset_settings.sounds[sound_name].volume = volume;
  }

  set_sound_mute(sound_name, mute) {
    this.sounds[sound_name].mute = mute;
    this.preset_settings.sounds[sound_name].mute = mute;
  }

  add_sound(sound_name) {
    this.sounds[sound_name] = { mute: true, volume: 50 };
    this.preset_settings.sounds[sound_name] = { mute: true, volume: 50 };
  }

  add_sounds(sounds) {
    for (var i = 0; i < sounds.length; i++) {
      this.add_sound(sounds[i]);
    }
  }

  remove_sound(sound_name) {
    delete this.sounds[sound_name];
    delete this.preset_settings.sounds[sound_name];
  }

  set_mute(mute) {
    this.mute = mute;
    this.preset_settings.mute = mute;
  }
}

class PresetsManager {
  // presets_manager_settings: json file with presets manager settings
  constructor(presets_manager_settings) {
    this.presets_manager_settings = JSON.parse(presets_manager_settings);
    [this.presets, this.presets_order, this.current_preset, this.presets_groups, this.current_group, this.groups_order] = this.load_presets();
    this.audio = new Audio_Player(this.current_preset);
    this.preset_change_queue = [];
    this.user_interacted = false;
  }
  load_presets() {
    try {
      let presets_groups = this.presets_manager_settings.presets_groups;
      let current_group = this.presets_manager_settings.current_group;
      let groups_order = this.presets_manager_settings.groups_order;
      // get presets order for current group
      let presets_order = this.presets_manager_settings.presets_groups[current_group];
      let presets = this.presets_from_presets_order(presets_order);
      let current_preset = presets[this.presets_manager_settings.current_preset];
      return [presets, presets_order, current_preset, presets_groups, current_group, groups_order];
    } catch (e) {
      console.log(e);
      return [null, null, null];
    }
  }

  presets_from_presets_order(presets_order) {
    let loaded_presets = JSON.parse(localStorage.getItem('presets'));
    let presets = {};
    for (var i = 0; i < presets_order.length; i++) {
      presets[presets_order[i]] = new Preset(loaded_presets[presets_order[i]]);
    }
    return presets;
  }

  get_preset(preset_name) {
    return this.presets[preset_name];
  }

  get_current_preset() {
    return this.current_preset;
  }

  save_current_preset_settings() {
    let presets = JSON.parse(localStorage.getItem('presets'));
    presets[this.current_preset.name] = this.current_preset.preset_settings;
    localStorage.setItem('presets', JSON.stringify(presets));
  }

  set_current_preset(preset_name) {
    this.first_user_interaction();
    this.save_current_preset_settings();
    this.current_preset = this.presets[preset_name];
    this.presets_manager_settings.current_preset = preset_name;
    this.audio.set_current_preset(this.current_preset);
    return this.current_preset;
  }

  next_preset() {
    let current_preset_index = this.presets_order.indexOf(this.current_preset.name);
    let next_preset_index = (current_preset_index + 1) % this.presets_order.length;
    this.set_current_preset(this.presets_order[next_preset_index]);
  }

  previous_preset() {
    let current_preset_index = this.presets_order.indexOf(this.current_preset.name);
    let previous_preset_index = (current_preset_index + this.presets_order.length - 1) % this.presets_order.length;
    this.set_current_preset(this.presets_order[previous_preset_index]);
  }

  add_preset(preset_name) {
    this.presets[preset_name] = new Preset({"name": preset_name, "volume": 50, "sounds": {}, "mute": false});
    this.presets_order.push(preset_name);
    let loaded_presets = JSON.parse(localStorage.getItem('presets'));
    loaded_presets[preset_name] = this.presets[preset_name].preset_settings;
    localStorage.setItem('presets', JSON.stringify(loaded_presets));
    this.presets_manager_settings.presets_groups[this.current_group].push(preset_name);
    this.presets_manager_settings.current_preset = preset_name;
    this.set_current_preset(preset_name);
    localStorage.setItem('presets_manager_settings', JSON.stringify(this.presets_manager_settings));
    return this.current_preset;
  }

  add_group(group_name) {
    let new_preset = this.add_preset(group_name + "_preset");
    this.presets_groups[group_name] = [new_preset.name];
    this.groups_order.push(group_name);
    this.set_current_group(group_name);
    this.set_current_preset(new_preset.name);
    this.presets_manager_settings.current_preset = new_preset.name;
    this.presets_manager_settings.current_group = group_name;
    this.presets_manager_settings.presets_groups = this.presets_groups;
    this.presets_manager_settings.groups_order = this.groups_order;
    localStorage.setItem('presets_manager_settings', JSON.stringify(this.presets_manager_settings));
    let loaded_presets = JSON.parse(localStorage.getItem('presets'));
    loaded_presets[new_preset.name] = new_preset.preset_settings;
    localStorage.setItem('presets', JSON.stringify(loaded_presets));
    return this.current_group;
  }

  remove_preset(preset_name) {
    this.first_user_interaction();
    let is_current_preset = this.current_preset.name === preset_name;
    let temp_presets_manager_settings = this.presets_manager_settings;
    this.audio.stop();
    let temp_presets = temp_presets_manager_settings.presets;
    if (is_current_preset) {
      let current_preset_index = temp_presets_manager_settings.presets_order.indexOf(preset_name);
      let previous_preset_index = (current_preset_index + temp_presets_manager_settings.presets_order.length - 1) % temp_presets_manager_settings.presets_order.length;
      temp_presets_manager_settings.current_preset = temp_presets_manager_settings.presets_order[previous_preset_index];
    }
    temp_presets_manager_settings.presets_order.splice(temp_presets_manager_settings.presets_order.indexOf(preset_name), 1);
    delete temp_presets[preset_name];
    // Remove preset from group
    temp_presets_manager_settings.presets_groups[temp_presets_manager_settings.current_group].splice(temp_presets_manager_settings.presets_groups[temp_presets_manager_settings.current_group].indexOf(preset_name), 1);
    this.presets_manager_settings = temp_presets_manager_settings;
    [this.presets, this.presets_order, this.current_preset] = this.load_presets();
    if (is_current_preset) {
      this.audio.set_current_preset(this.current_preset);
    }
    localStorage.setItem("presets_manager_settings", JSON.stringify(this.presets_manager_settings));
  }

  change_preset_order(preset_name, new_index) {
    this.presets_order.splice(this.presets_order.indexOf(preset_name), 1);
    this.presets_order.splice(new_index, 0, preset_name);
  }

  change_preset_name(preset_name, new_preset_name) {
    this.presets[new_preset_name] = this.presets[preset_name];
    this.presets[new_preset_name].name = new_preset_name;
    this.presets[new_preset_name].preset_settings.name = new_preset_name;
    this.presets_order[this.presets_order.indexOf(preset_name)] = new_preset_name;
    if (this.current_preset.name === preset_name) {
      this.current_preset.name = new_preset_name;
    }
    this.presets_groups[this.current_group][this.presets_groups[this.current_group].indexOf(preset_name)] = new_preset_name;
    let loaded_presets = JSON.parse(localStorage.getItem('presets'));
    loaded_presets[new_preset_name] = this.presets[new_preset_name].preset_settings;
    delete loaded_presets[preset_name];
    localStorage.setItem('presets', JSON.stringify(loaded_presets));
    delete this.presets[preset_name];
    this.presets_manager_settings.presets_groups = this.presets_groups;
    this.presets_manager_settings.presets_order = this.presets_order;
    this.presets_manager_settings.current_preset = this.current_preset.name;
    localStorage.setItem('presets_manager_settings', JSON.stringify(this.presets_manager_settings));
    return this.presets[new_preset_name];

  }

  set_preset_volume(preset_name, volume) {
    this.first_user_interaction();
    this.presets[preset_name].set_volume(volume);
    this.audio.set_volume(volume);
    return this.presets[preset_name].volume;
  }

  set_sound_volume(sound_name, volume) {
    this.first_user_interaction();
    this.current_preset.set_sound_volume(sound_name, volume);
    this.audio.set_sound_volume(sound_name, volume);
  }

  set_sound_mute(sound_name, mute) {
    this.first_user_interaction();
    this.current_preset.set_sound_mute(sound_name, mute);
    this.audio.set_sound_mute(sound_name, mute);
  }

  // To current preset
  add_sounds(sounds) {
    this.first_user_interaction();
    let loaded_presets = JSON.parse(localStorage.getItem("presets"));
    this.current_preset.add_sounds(sounds);
    let presets_manager_settings = this.presets_manager_settings;
    presets_manager_settings.presets[this.current_preset.name] = this.current_preset;
    this.presets_manager_settings = presets_manager_settings;
    localStorage.setItem("presets_manager_settings", JSON.stringify(this.presets_manager_settings));
    this.presets_order = this.presets_manager_settings.presets_order;
    loaded_presets[this.current_preset.name].sounds = this.current_preset.sounds;
    this.presets[this.current_preset.name] = new Preset(loaded_presets[this.current_preset.name]);
    this.current_preset = this.presets[this.presets_manager_settings.current_preset];
    this.audio.current_preset = this.current_preset;
    localStorage.setItem("presets", JSON.stringify(loaded_presets));
    this.audio.add_sounds(sounds);
    return this.current_preset.sounds;
  }

  first_user_interaction() {
    if (!this.first_user_interaction_done) {
      this.audio.first_user_interaction();
      this.first_user_interaction_done = true;
    }
  }

  remove_sound(sound_name) {
    this.first_user_interaction();
    this.audio.remove_sound(sound_name);
    this.current_preset.remove_sound(sound_name);
    return this.current_preset.sounds;
  }

  mute_current_preset(mute) {
    this.first_user_interaction();
    this.current_preset.set_mute(mute);
    this.audio.set_mute(mute);
  }

  async play() {
    this.first_user_interaction();
    await this.audio.play();
  }

  stop() {
    this.first_user_interaction();
    this.audio.stop();
  }

  set_current_group(group_name) {
    this.first_user_interaction();
    this.current_group = group_name;
    this.presets_order =  this.presets_manager_settings.presets_groups[group_name];
    this.presets = this.presets_from_presets_order(this.presets_order);
    // Set current preset to first preset in group
    this.current_preset = this.set_current_preset(this.presets_order[0]);
  }

  next_group() {
    this.first_user_interaction();
    let current_group_index = this.groups_order.indexOf(this.current_group);
    let next_group_index = current_group_index + 1;
    if (next_group_index >= this.groups_order.length) {
      next_group_index = 0;
    }
    this.set_current_group(this.groups_order[next_group_index]);
  }

  previous_group() {
    this.first_user_interaction();
    let current_group_index = this.groups_order.indexOf(this.current_group);
    let previous_group_index = current_group_index - 1;
    if (previous_group_index < 0) {
      previous_group_index = this.groups_order.length - 1;
    }
    this.set_current_group(this.groups_order[previous_group_index]);
  }
}
// Preset attributes: name, mute, volume, sounds. Methods: set_mute, set_volume, set_sound_volume, set_sound_mute, add_sound, remove_sound. Constructor: Preset(preset_settings)
// PresetsManager attributes: presets, presets_order, current_preset. Methods: get_preset, get_current_preset, set_current_preset, next_preset, previous_preset, add_preset, remove_preset, change_preset_order, change_preset_name, set_preset_volume, set_sound_volume, set_sound_mute, add_sound, add_sounds, remove_sound, mute_current_preset. Constructor: PresetsManager(presets_manager_settings)

export default PresetsManager;