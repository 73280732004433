// This is the main component of the app, for now it shows Home.js component

import React from "react";
import Home from "./components/Home";
import CookieConsent, { Cookies } from "react-cookie-consent";
import "./App.css";
// Bootstrap
import "bootswatch/dist/darkly/bootstrap.min.css";
// from AnalyticsContext.jsx
import { AnalyticsProvider } from "./components/AnalyticsContext";


const handleAcceptCookie = () => {
  // ReactGA.initialize(trackingId);
  // console.log("ID: ", trackingId);
};
const handleDeclineCookie = () => {
  Cookies.remove("_ga");
  Cookies.remove("_gid");
  Cookies.remove("_gat");
  Cookies.remove("_ga_SD121GG2FV");
};

function App() {
  const playerRef = React.useRef();
  const audioRef = React.useRef(new Audio());

  React.useEffect(() => {
    audioRef.current.src = "/silence.mp3";
    audioRef.current.volume = 0.00001;
    audioRef.current.loop = true;
    audioRef.current.load();
  }, []);

  const onPlayPause = React.useCallback((new_state) => {
    if (new_state === "play") {
      playerRef.current.playPause();
      audioRef.current.play();
    } else {
      playerRef.current.playPause();
      audioRef.current.pause();
    }
  }, []);

  React.useEffect(() => {
    async function handleMediaSession() {
      const playAudio = () => {
        // Play the audio only if this is the first user interaction with the site
        if (navigator.userGesture) {
        } else {
          window.addEventListener("click", function firstUserGesture() {
            window.removeEventListener("click", firstUserGesture);
            audioRef.current.play();
            playerRef.current.attachAudio(audioRef.current);
            navigator.mediaSession.setActionHandler("play", () => {
              onPlayPause("play");
            });
            navigator.mediaSession.setActionHandler("pause", () => {
              onPlayPause("pause");
            });
            navigator.mediaSession.setActionHandler("previoustrack", () => {
              playerRef.current.prevPreset();
            });
            navigator.mediaSession.setActionHandler("nexttrack", () => {
              playerRef.current.nextPreset();
            });
          });
        }
      };
  
      // Play the audio immediately if the user has already interacted with the page
      if (navigator.userGesture) {
      } else {
        // Wait for the first user interaction with the site
        window.addEventListener("click", playAudio, { once: true });
        window.addEventListener("keydown", playAudio, { once: true });
      }
    }
  
    handleMediaSession();
  }, [audioRef.current, onPlayPause]);

  return (
    <AnalyticsProvider>
    <div className="App">
      <Home playerRef={playerRef} />
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText="Yeah, okay"
        declineButtonText="Decline"
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
    </AnalyticsProvider>
  );
}

export default App;
