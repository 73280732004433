// Audio component, uses Web Audio API to play audio files from sounds folder
// It contains class Audio_Player which is responsible for playing audio files. It has methods: build, play, set_current_preset, set_sound_mute, set_sound_volume, set_volume, stop

// First, Audiobase class. It controls single audio file, assigns it to a channel, sets volume, mute, etc.
class AudioBase {
    constructor(audio_context, audio_name, audio_path, mute, volume) {
        this.audio_name = audio_name;
        this.audio_path = audio_path;
        this.mute = mute;
        this.volume = volume;
        this.audio_context = audio_context;
        this.loop = true;
        this.audio_source = null;
        this.gain_node = null;
        this.loadBuffer();
    }

    async loadBuffer() {
        const response = await fetch(this.audio_path);
        const arrayBuffer = await response.arrayBuffer();

        return arrayBuffer;
    }

    async setVolume(volume) {
        if (!this.gain_node) {
            await this.loadHandler();
        }
        this.volume = volume;
        this.gain_node.gain.value = this.mute ? 0 : this.volume / 100;
    }

    async setMute(mute) {
        if (!this.gain_node) {
            await this.loadHandler();
        }
        this.mute = mute;
        this.gain_node.gain.value = this.mute ? 0 : this.volume / 100;
    }

    async loadHandler() {
        this.audio_source = this.audio_context.createBufferSource();
        this.gain_node = this.audio_context.createGain();
        this.gain_node.gain.value = this.mute ? 0 : this.volume / 100;
        this.audio_source.connect(this.gain_node);
        this.gain_node.connect(this.audio_context.destination);
        this.audio_source.loop = this.loop;
        const arrayBuffer = await this.loadBuffer();
        const audioBuffer = await this.audio_context.decodeAudioData(arrayBuffer);
        this.audio_source.buffer = audioBuffer;
    }

    async play() {
        if (!this.audio_source) {
            await this.loadHandler();
        }
        this.audio_source.loop = this.loop;
        try {
            this.audio_source.start();
        } catch (e) {
            // if 'cannot call start more than once.' in error, connect it to destination again
            if (e.message.includes("cannot call start more than once")) {
                this.audio_source.connect(this.gain_node);
                // this.audio_source.start();
            }
            // if 'Start has already been called on this AudioBufferSourceNode.'
            else if (e.message.includes("Start has already been called on this AudioBufferSourceNode")) {
                this.audio_source.disconnect();
                this.audio_source.connect(this.gain_node);
                // this.audio_source.start();
            }
            else if (e.message.includes("The buffer passed to decodeAudioData contains an unknown content type.")) {
                console.log("Cannot play " + this.audio_name + ". File not found.");
            }
            else {
                console.log(e.message);
            }
        }
    }

    async stop() {
        try {
            this.audio_source.disconnect();
        } catch (e) {
            if (e.message.includes("Cannot read properties of null")) {
                console.log("Audio already stopped");
            }
            else {
                console.log(e.message);
            }
        }
    }
}

// It holds multiple AudioBase objects, one for each sound in preset. It also holds current preset from which it gets sound names, paths and volumes as well as preset mute and volume

class Audio_Player {
    constructor(current_preset) {
        this.state = {
            current_preset: current_preset,
            sounds: current_preset.sounds,
            preset_mute: current_preset.mute,
            preset_volume: current_preset.volume / 100,
            localStorageSounds: JSON.parse(localStorage.getItem("sounds"))
        };
        this.constructing = true;
        this.audio_context = null;
        this.audio_objects = null;
        this.html_element = null;
    }

    first_user_interaction() {
        this.audio_context = new AudioContext();
        this.audio_objects = this.build();
        this.constructing = false;
    }

    build(){
        // Build audio objects from current preset
        let audio_objects = {};
        // Iterate over sounds dictionary and create AudioBase objects for each sound
        for (let sound_name in this.state.sounds) {
            // if sound_name is not in audio_objects, create new AudioBase object and add it to audio_objects
            if (!(sound_name in audio_objects)) {
                // Log all info about volume
                var audio_path = this.state.localStorageSounds[sound_name];
                var mute = this.state.sounds[sound_name].mute;
                var volume = this.state.sounds[sound_name].volume;
                if (this.state.preset_mute) {
                    mute = true;
                }
                volume = volume * this.state.preset_volume;
                audio_objects[sound_name] = new AudioBase(this.audio_context, sound_name, audio_path, mute, volume);
            }
            else {
                console.log("Sound already exists");
            }
        }
        return audio_objects;
    }

    async play() {
        // Play all sounds in preset
        for (let sound_name in this.state.sounds) {
            this.audio_objects[sound_name].play();
        }
    }

    stop() {
        // Stop all sounds in preset
        for (let sound_name in this.state.sounds) {
            this.audio_objects[sound_name].stop();
        }
    }

    set_sound_mute(sound_name, mute) {
        // Set mute for a single sound
        this.audio_objects[sound_name].setMute(mute || this.state.preset_mute);
    }

    set_sound_volume(sound_name, volume) {
        // Set volume for a single sound
        this.audio_objects[sound_name].setVolume(volume);
        if (this.state.preset_mute) {
            this.audio_objects[sound_name].setMute(false);
        }
    }

    set_mute(mute) {
        // Set mute for all sounds in preset
        this.state.preset_mute = mute;
        for (let sound_name in this.state.sounds) {
            this.audio_objects[sound_name].setMute(mute || this.state.sounds[sound_name].mute);
        }
    }

    set_volume(volume) {
        // Set volume for all sounds in preset
        this.state.preset_volume = volume / 100;
        for (let sound_name in this.state.sounds) {
            this.audio_objects[sound_name].setVolume(this.state.sounds[sound_name].volume * this.state.preset_volume);
            if (this.state.preset_mute && !this.state.sounds[sound_name].mute) {
                this.audio_objects[sound_name].setMute(false);
            }
        }
    }

    async set_current_preset(new_current_preset) {
        // Set current preset
        var same_preset = new_current_preset.name === this.state.current_preset.name
        this.state.preset_mute = new_current_preset.mute;
        this.state.preset_volume = new_current_preset.volume / 100;
        this.state.current_preset = new_current_preset;
        this.state.sounds = new_current_preset.sounds;
        if (same_preset) {
            this.play();
        }
        else {
            this.preset_change();
        }
    }

    async remove_sound(sound_name) {
        // Remove sound from preset
        await this.audio_objects[sound_name].stop();
        delete this.audio_objects[sound_name];
        delete this.state.sounds[sound_name];
    }

    preset_change() {
        let new_audio_objects = this.build();
        var new_sounds = Object.keys(new_audio_objects);
        var old_sounds = Object.keys(this.audio_objects);
        var sounds_to_remove = old_sounds.filter(key => !new_audio_objects.hasOwnProperty(key));
        var sounds_to_add = new_sounds.filter(key => !this.audio_objects.hasOwnProperty(key));
        var sounds_to_update = new_sounds.filter(key => this.audio_objects.hasOwnProperty(key));
        for (let sound_name of sounds_to_remove) {
            try {
                this.audio_objects[sound_name].stop();
            }
            catch (e) {
                console.log("Error while stopping sound: " + sound_name, e);
            }
            // Remove sound from audio_objects
            delete this.audio_objects[sound_name];
        }
        for (let sound_name of sounds_to_add) {
            new_audio_objects[sound_name].play();
            this.audio_objects[sound_name] = new_audio_objects[sound_name];
        }
        for (let sound_name of sounds_to_update) {
            this.set_sound_volume(sound_name, new_audio_objects[sound_name].volume);
            this.set_sound_mute(sound_name, new_audio_objects[sound_name].mute);
        }
    }

    add_sound(sound_name) {
        var audio_path = this.state.localStorageSounds[sound_name];
        var mute = this.state.sounds[sound_name].mute;
        var volume = this.state.sounds[sound_name].volume;
        if (this.state.preset_mute) {
            mute = true;
        }
        volume = volume * this.state.preset_volume;
        this.audio_objects[sound_name] = new AudioBase(this.audio_context, sound_name, audio_path, mute, volume);
        this.audio_objects[sound_name].play();
    }

    add_sounds(sound_names) {
        this.state.localStorageSounds = JSON.parse(localStorage.getItem("sounds"));
        this.state.sounds = this.state.current_preset.sounds;
        for (let sound_name of sound_names) {
            this.add_sound(sound_name);
        }
    }
}

export default Audio_Player;
