// Component managing links to social media
// Horizontal list of social media links

import React from "react";
// simple-icons
import { SiDiscord, SiTwitter, SiReddit, SiSubstack } from "react-icons/si";
// bootstrap
import { Container, Row, Col } from "react-bootstrap";
// analytics
import { useAnalytics } from "./AnalyticsContext";

function Socials() {
  // Styling for all icons
  const iconStyle = {
    color: "var(--bs-info)",
    // padding: "0.5rem",
    // margin: "0.5rem",
    width: "1.5rem",
    height: "1.5rem",
  };

  return (
    <Container>
      <Row>
        <Col>
          <h5>Stay in touch with creator on </h5>
        </Col>
      </Row>
      <div id="socials">
        <Row>
          <Col>
            <a
              href="https://twitter.com/teleoflexuous"
              target="_blank"
              rel="noreferrer"
              onClick={useAnalytics().event({
                category: "Social Media",
                action: "Twitter",
                value: 5,
              })}
            >
              <SiTwitter style={iconStyle} />
            </a>
          </Col>
          <Col>
            <a
              href="https://incentiveassemblage.substack.com/"
              target="_blank"
              rel="noreferrer"
              onClick={useAnalytics().event({
                category: "Social Media",
                action: "Substack",
                value: 5,
              })}
            >
              <SiSubstack style={iconStyle} />
            </a>
          </Col>
          <Col>
            <a
              href="https://discord.gg/m3WppcPeYy"
              target="_blank"
              rel="noreferrer"
              onClick={useAnalytics().event({
                category: "Social Media",
                action: "Discord",
                value: 5,
              })}
            >
              <SiDiscord style={iconStyle} />
            </a>
          </Col>
          <Col>
            <a
              href="https://www.reddit.com/r/StimulantNoise/"
              target="_blank"
              rel="noreferrer"
              onClick={useAnalytics().event({
                category: "Social Media",
                action: "Reddit",
                value: 5,
              })}
            >
              <SiReddit style={iconStyle} />
            </a>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Socials;
