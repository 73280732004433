import React from "react";
import Button from "react-bootstrap/Button";
import { useAnalytics } from "./AnalyticsContext";

// Button with all Button bootstrap props and optional analytics props. Can replace all bootstrap buttons. onClick is optional and will be called if provided.
// Analytics props are optional and will not be tracked if not provided.
// Default { category, action, label, value, nonInteraction, transport } are { "Button", "click", "default", 0, false, "xhr" }

const AnalyticsButton = ({
  children,
  onClick,
  category = "Button",
  action = "click",
  label = "default",
  value = 0,
  nonInteraction = false,
  transport = "xhr",
  ...rest
}) => {
  const analytics = useAnalytics();

  const handleClick = (e) => {
    analytics.event({
      category,
      action,
      label,
      value,
      nonInteraction,
      transport,
    });

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button onClick={handleClick} {...rest}>
      {children}
    </Button>
  );
};

export default AnalyticsButton;

// Example usage
// <AnalyticsButton
//   onClick={handleClick}
//   category="Button"
//   action="click"
//   label="default"
//   value={0}
//   nonInteraction={false}
//   transport="xhr"
// >
//   Click me
// </AnalyticsButton>
