// HomePage Component
// It has a title and tagline on top, Player component below and few parallel paragraphs below that

import React from "react";
import Player from "./Player";
import Explanation from "./Explanation.js";
import { Container, Row, Col } from "react-bootstrap";
import Socials from "./Socials";
import { useAnalytics } from "./AnalyticsContext";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const Home = ({ playerRef }) => {
  useAnalytics().send("pageview", "Home");
  console.log("Nothing to see here, move along");
  const driverObj = driver({
    animate: true,
    showProgress: true,
    opacity: 0.75,
    steps: [
      {
        popover: {
          title: "Welcome to Stimulant Noise!",
          description: "This is a 45 seconds guide to get you started.",
        },
        position: "center",
      },
      {
        element: "#player",
        popover: {
          title: "Preset",
          description:
            "This is a preset for High Stimulation Human-like Noises.",
        },
      },
      {
        element: "#current_preset",
        popover: {
          title: "Controls",
          description:
            "You can Play/Pause, Mute and control the Volume of that preset.",
        },
      },
      {
        element: "#sounds",
        popover: {
          title: "Sounds",
          description:
            "It's made up 6 different sounds, each with their own volume control and delete button.",
        },
      },
      {
        element: "#add_sounds",
        popover: {
          title: "Add sounds",
          description: "Or add a new one - from local files.",
        },
      },
      {
        element: "#presets_group",
        popover: {
          title: "Presets Group",
          description: "Here you have all Human-like Noise presets.",
        },
      },
      {
        element: "#presets_names_Human",
        popover: {
          title: "Presets Names",
          description: "You can swap between them directly or...",
        },
      },
      {
        element: "#presets_buttons_Human",
        popover: {
          title: "Load Change",
          description:
            "You can think about lowering/rising your cognitive load instead. You can also use your media buttons, like play/pause, next/previous - even when it's in the background/your phone is locked.",
        },
      },
      {
        element: "#presets_group",
        popover: {
          title: "Presets",
          description: "You can switch between groups with arrows.",
        },
      },
      {
        element: "#explanation",
        popover: {
          title: "Explanation",
          description:
            "If you want to learn more about theoretical background and research used to create this app look here.",
          side: "top",
        },
      },
      {
        element: "#socials",
        popover: {
          title: "socials",
          description: "To find author, you can pick a place here.",
          side: "bottom",
        },
      },
    ],
    // onNextClick: (step) => {
    //   useAnalytics().send("event", {
    //     category: "Onboarding",
    //     action: "Next",
    //     label: step,
    //     value: 1,
    //   });
    // },
  });
  driverObj.drive();

  return (
    // Centered Title on top, tagline below. Social media to the right of the title. Title centered regardless of social media presence.
    // Title: Stimulant Noise. Tagline: Stay stimulated to stay focused.
    <Container style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
      {/* Right aligned social media links */}
        <Row>
          <Col md={{ span: 3, offset: 9 }} style={{ textAlign: "right" }}>
            <Socials />
          </Col>
        </Row>
      {/* Title and tagline */}
      <Row>
        <Col>
          <h1>Stimulant Noise</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Stay stimulated to stay focused.</h4>
        </Col>
      </Row>
      {/* Player Component */}
      <Row style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
        <Col>
          <Player ref={playerRef} />
        </Col>
      </Row>
      {/* Explanation Component */}
        <Row>
          <Col>
            <Explanation />
          </Col>
        </Row>
    </Container>
  );
};

export default Home;
