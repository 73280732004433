// 3 columns with text and images

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useAnalytics } from "./AnalyticsContext";

const Explanation = () => {
  const [showModal, setShowModal] = React.useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    // Justify to the left, 3 columns
    <Container>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Body>
          <img
            id="img"
            src="https://github.com/teleoflexuous/Noise-Stimulant/blob/main/img.png?raw=true"
            alt="Yerkes-Dodson Law"
            width="100%"
          />
        </Modal.Body>
      </Modal>
      <div id="explanation">
        <Row>
          <Col xs={12} md={6} lg={4}>
            <h2>Why is it different?</h2>
            <p>
              The point of this noise generator is not to give you a plain
              background noise. It allows you to regulate auditory cognitive
              load with ease to adjust to your current task, so that you can
              keep focus when you feel bored or distracted.
              <br />
              <br />
              Left side is your typical noise generator. Use existing sounds,
              add new ones, mute them, change volumes, the usual stuff. On the
              right side however, you have topical groups of sound sets. You can
              quickly move within the group to adjust intensity of noise.
              <br />
              <br />
            </p>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <h2>How to use it?</h2>
            <p>
              Pick a topic from the right side by clicking &lt; or &gt; buttons.
              Generally intensity of sets goes: Nature &gt; Industrial &gt;
              Human, with Noise and BPM Forest being their own things, but pick
              something that doesn't annoy you more than you want first and
              foremost.
              <br />
              <br />
              Once you have a topic, you can adjust the intensity of the noise
              with Lower and Raise buttons, as well as with Forward &gt;&gt;|
              and |&lt;&lt; Backward buttons. If those buttons don't work for
              you for whatever reason, you can download local open source
              version from:&nbsp;
              <a
                href="https://github.com/teleoflexuous/Noise-Stimulant"
                target="_blank"
                rel="noreferrer"
                onClick={useAnalytics().event({
                  category: "Social Media",
                  action: "GitHub",
                  value: 10,
                })}
              >
                Stimulant Noise on GitHub
              </a>
              , because by switching back to the page all the time you'll miss
              the whole point. Unless you have multiple monitors, I guess.
              <br />
              <br />
              Once you picked a topic, set the intensity (probably to high) and
              start doing whatever you planned on doing. If you feel too
              distracted, you can lower the intensity of the noise with
              |&lt;&lt; Backward button. If you feel like you're not getting
              enough noise, you can raise the intensity back with Forward
              &gt;&gt;| button.
            </p>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <h2>Why?</h2>
            <p>
              Dynamically adjusting auditory cognitive load allows you to keep
              focus on your task, while not being distracted by the noise.
              Basically it lets you stay on top of this curve:
              {/* image at https://github.com/teleoflexuous/Noise-Stimulant/blob/main/img.png sized to match column width. Click to enlarge in a Modal */}
              <a href="#" onClick={handleOpenModal}>
                <img
                  id="img"
                  src="https://github.com/teleoflexuous/Noise-Stimulant/blob/main/img.png?raw=true"
                  alt="Yerkes-Dodson Law"
                  width="100%"
                />
              </a>
              <br />
              If you want to know more, I wrote about it here:
              <br />
              <a
                href="https://incentiveassemblage.substack.com/p/why-is-nobody-serious-about-managing"
                target="_blank"
                rel="noreferrer"
                onClick={useAnalytics().event({
                  category: "Social Media",
                  action: "Substack",
                  label:
                    "Why Is Nobody Serious About Managing ADHD With Sound?",
                  value: 10,
                })}
              >
                Why Is Nobody Serious About Managing ADHD With Sound?
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Explanation;
