import React from "react";
import ReactGA from "react-ga4";
const trackingId = "G-SD121GG2FV";

const AnalyticsContext = React.createContext();

const AnalyticsProvider = ({ children }) => {
  React.useEffect(() => {
    ReactGA.initialize(trackingId);
  }, []);

  return (
    <AnalyticsContext.Provider value={ReactGA}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = () => {
  const analytics = React.useContext(AnalyticsContext);

  if (!analytics) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }

  return analytics;
};

export { AnalyticsProvider, useAnalytics };
