// Player Component, it displays the audio player and makes use of Presets.js component.

// On the left side there's current preset control panel, on the right side there are presets control panel

// Preset attributes: name, mute, volume, sounds. Methods: set_mute, set_volume, set_sound_volume, set_sound_mute, add_sound, remove_sound. Constructor: Preset(preset_settings)
// PresetsManager attributes: presets, presets_order, current_preset. Methods: get_preset, get_current_preset, set_current_preset, next_preset, previous_preset, add_preset, remove_preset, change_preset_order, change_preset_name, set_preset_volume, set_sound_volume, set_sound_mute, add_sound, add_sounds, remove_sound, mute_current_preset. Constructor: PresetsManager(presets_manager_settings)

// Preset control panel contains preset volume slider, preset name, option to mute/unmute preset, option to delete preset, button to add new sounds and list of audio files: each with its own volume slider, mute/unmute button and delete button
// Presets control panel contains list of presets: Button to switch to preset, add new preset button and buttons to play next/previous preset

import React, { Component } from "react";
import PresetsManager from "./Presets";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import ToggleButton from "react-bootstrap/ToggleButton";
import presets_manager_settings from "./presets_manager_settings";
import AnalyticsButton from "./AnalyticsButton";

import {
  FaTrash,
  FaPlus,
  FaPencilAlt,
  FaVolumeMute,
  FaVolumeUp,
  FaVolumeDown,
  FaPlay,
  FaPause,
  FaArrowUp,
  FaArrowDown,
} from "react-icons/fa";

if (localStorage.getItem("presets_manager_settings") === null) {
  localStorage.setItem(
    "presets_manager_settings",
    JSON.stringify(presets_manager_settings)
  );
}

// Player component, which will be exported at the end of the file to be used in Home.js component and finally displayed in App.js component
// Lets avoid repeating code that's already provided by PresetsManager component and use it's methods
// Modal window to add new preset

class Player extends Component {
  constructor(props) {
    super(props);
    this.presets_manager = new PresetsManager(
      localStorage.getItem("presets_manager_settings")
    );
    this.state = {
      current_preset: this.presets_manager.current_preset,
      presets: this.presets_manager.presets,
      presets_order: this.presets_manager.presets_order,
      current_group: this.presets_manager.current_group,
      presets_groups: this.presets_manager.presets_groups,
      showChangePresetNameModal: false,
      showDeletePresetModal: false,
      showAddSoundsModal: false,
      showDeleteSoundModal: false,
      showAddPresetModal: false,
      showAddGroupModal: false,
      playing: false,
      media_session: false,
      sounds: JSON.parse(localStorage.getItem("sounds")),
    };
    // Create dummy audio object to be able to use MediaSession API
    this.audio = null;
    this.new_sounds = [];

    this.attachMediaSession = this.attachMediaSession.bind(this);
  }

  // Send page view event to Google Analytics on page load

  updateStateFromPresetsManager() {
    this.setState(() => ({
      current_preset: this.presets_manager.current_preset,
      presets: this.presets_manager.presets,
      presets_order: this.presets_manager.presets_order,
      current_group: this.presets_manager.current_group,
      presets_groups: this.presets_manager.presets_groups,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.playing && !prevState.playing) {
      this.attachAudio();
    }
    if (this.state.current_preset !== prevState.current_preset) {
      this.updateMediaSession();
    }
  }

  play() {
    this.presets_manager.play();
    this.setState({ playing: true });
    navigator.mediaSession.playbackState = "playing";
  }

  pause() {
    this.presets_manager.stop();
    this.setState({ playing: false });
    navigator.mediaSession.playbackState = "paused";
  }

  playPause() {
    if (this.state.playing) {
      this.pause();
    } else {
      this.play();
    }
    this.setState({ playing: !this.state.playing });
  }

  openChangePresetNameModal = () => {
    this.setState({ showChangePresetNameModal: true });
  };

  changePresetName = (new_preset_name) => {
    this.setState((prevState) => ({
      current_preset: this.presets_manager.change_preset_name(
        prevState.current_preset.name,
        new_preset_name
      ),
    }));
    this.updateStateFromPresetsManager();
    this.setState({ showChangePresetNameModal: false });
  };

  openDeletePresetModal = () => {
    this.setState({ showDeletePresetModal: true });
  };

  deletePreset = () => {
    this.presets_manager.remove_preset(this.state.current_preset.name);
    this.setState(() => ({
      current_preset: this.presets_manager.current_preset,
      presets: this.presets_manager.presets,
      presets_order: this.presets_manager.presets_order,
      current_group: this.presets_manager.current_group,
      presets_groups: this.presets_manager.presets_groups,
    }));
    this.setState({ showDeletePresetModal: false });
  };

  openAddSoundsModal = () => {
    this.setState({ showAddSoundsModal: true });
  };

  addSounds = () => {
    this.setState((prevState) => ({
      current_preset: {
        ...prevState.current_preset,
        sounds: this.presets_manager.add_sounds(this.new_sounds),
      },
    }));
    // this.new_sounds = [];
    this.setState({ showAddSoundsModal: false });
  };

  // new_sounds = e.target.files;
  uploadSounds = (new_sounds) => {
    var promises = [];
    var temp_sounds = localStorage.getItem("sounds");
    temp_sounds = JSON.parse(temp_sounds);
    for (let i = 0; i < new_sounds.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(new_sounds[i]);
      promises.push(
        new Promise((resolve) => {
          reader.onloadend = function () {
            temp_sounds[new_sounds[i].name] = reader.result;
            resolve();
          };
        })
      );
    }
    Promise.all(promises).then(() => {
      localStorage.setItem("sounds", JSON.stringify(temp_sounds));
      this.setState({ sounds: temp_sounds });
    });
  };

  openDeleteSoundModal = (sound_name) => {
    this.setState({ showDeleteSoundModal: sound_name });
  };

  deleteSound = (sound_name) => {
    var new_sounds = this.presets_manager.remove_sound(sound_name);
    this.setState((prevState) => ({
      current_preset: {
        ...prevState.current_preset,
        sounds: new_sounds,
        current_preset: this.presets_manager.current_preset,
      },
    }));
    this.setState({ showDeleteSoundModal: false });
  };

  openAddPresetModal = () => {
    this.setState({ showAddPresetModal: true });
  };

  addPreset = (new_preset_name) => {
    this.setState((prevState) => ({
      current_preset: {
        ...prevState.current_preset,
        name: this.presets_manager.add_preset(new_preset_name),
      },
    }));
    this.setState({ showAddPresetModal: false });
  };

  openAddGroupModal = () => {
    this.setState({ showAddGroupModal: true });
  };

  addGroup = (new_group_name) => {
    this.setState((prevState) => ({
      current_group: this.presets_manager.add_group(new_group_name),
    }));
    this.setState({ showAddGroupModal: false });
    this.updateStateFromPresetsManager();
  };

  PresetVolumeChange = (e) => {
    this.presets_manager.mute_current_preset(false);
    this.setState((prevState) => ({
      current_preset: {
        ...prevState.current_preset,
        volume: this.presets_manager.set_preset_volume(
          prevState.current_preset.name,
          e.target.value
        ),
        playing: true,
        muted: false,
        // Unmute the preset if it was muted
      },
    }));
  };

  PresetMuteChange = (mute) => {
    this.presets_manager.mute_current_preset(mute);
    // Re-render the component
    this.setState({ current_preset: this.presets_manager.current_preset });
  };

  changePreset = (preset_name) => {
    this.setState((prevState) => ({
      current_preset: this.presets_manager.set_current_preset(preset_name),
      presets: this.presets_manager.presets,
      presets_order: this.presets_manager.presets_order,
      playing: true,
    }));
  };

  muteSound = (sound_name, is_muted) => {
    this.presets_manager.set_sound_mute(sound_name, is_muted);
    this.setState({ current_preset: this.presets_manager.current_preset });
  };

  SoundVolumeChange = (sound_name, volume) => {
    this.presets_manager.set_sound_mute(sound_name, false);
    this.presets_manager.set_sound_volume(sound_name, volume);
    this.setState({
      current_preset: this.presets_manager.current_preset,
      muted: false,
    });
  };

  prevPreset = () => {
    this.presets_manager.previous_preset();
    this.updateStateFromPresetsManager();
  };

  nextPreset = () => {
    this.presets_manager.next_preset();
    this.updateStateFromPresetsManager();
    this.play();
  };

  setGroup = (group_name) => {
    this.presets_manager.set_current_group(group_name);
    this.updateStateFromPresetsManager();
  };

  prevGroup = () => {
    this.presets_manager.previous_group();
    this.updateStateFromPresetsManager();
  };

  nextGroup = () => {
    this.presets_manager.next_group();
    this.updateStateFromPresetsManager();
  };

  render() {
    return (
      // Before we define left and right side of the player, lets define common container
      // Left side should take 2/3 of the width, right side should take 1/3 of the width
      <Container fluid style={{ margin: "5", padding: "5", maxWidth: "100%" }}>
        {/* Modals */}
        {/* Modal window to change preset name */}
        <Modal
          show={this.state.showChangePresetNameModal}
          onHide={() => this.setState({ showChangePresetNameModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change preset name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.changePresetName(
                  document.getElementById("changePresetName").value
                );
              }}
            >
              <Form.Group controlId="changePresetName">
                <Form.Label>New preset name</Form.Label>
                <Form.Control type="text" placeholder="Enter new preset name" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <AnalyticsButton
              variant="secondary"
              onClick={() =>
                this.setState({ showChangePresetNameModal: false })
              }
              category="Preset"
              action="Change name"
              label="Close"
            >
              Close
            </AnalyticsButton>
            <AnalyticsButton
              variant="primary"
              type="submit"
              onClick={() =>
                this.changePresetName(
                  document.getElementById("changePresetName").value
                )
              }
              category="Preset"
              action="Change name"
              label="Save Changes"
            >
              Save Changes
            </AnalyticsButton>
          </Modal.Footer>
        </Modal>
        {/* Modal window to delete preset */}
        <Modal
          show={this.state.showDeletePresetModal}
          onHide={() => this.setState({ showDeletePresetModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete preset</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this preset?</p>
          </Modal.Body>
          <Modal.Footer>
            <AnalyticsButton
              variant="secondary"
              onClick={() => this.setState({ showDeletePresetModal: false })}
              category="Preset"
              action="Delete"
              label="Close"
            >
              Close
            </AnalyticsButton>
            <AnalyticsButton
              variant="danger"
              type="submit"
              onClick={() => this.deletePreset()}
              category="Preset"
              action="Delete"
              label="Delete"
            >
              Delete
            </AnalyticsButton>
          </Modal.Footer>
        </Modal>
        {/* Modal window to add preset */}
        <Modal
          show={this.state.showAddPresetModal}
          onHide={() => this.setState({ showAddPresetModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add preset</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enter new preset name.</p>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.addPreset(document.getElementById("addPresetName").value);
              }}
            >
              <Form.Group controlId="addPresetName">
                <Form.Label>Preset name</Form.Label>
                <Form.Control type="text" placeholder="Enter new preset name" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <AnalyticsButton
              variant="secondary"
              onClick={() => this.setState({ showAddPresetModal: false })}
              category="Preset"
              action="Add"
              label="Close"
            >
              Close
            </AnalyticsButton>
            <AnalyticsButton
              variant="primary"
              type="submit"
              onClick={() =>
                this.addPreset(document.getElementById("addPresetName").value)
              }
              category="Preset"
              action="Add"
              label="Add"
              value={1}
            >
              Add
            </AnalyticsButton>
          </Modal.Footer>
        </Modal>
        {/* Modal window to add sound to preset */}
        <Modal
          show={this.state.showAddSoundsModal}
          onHide={() => this.setState({ showAddSoundsModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add sounds to current preset</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Select sound files you want to add to the current preset.</p>
            {/* List of all state.sounds that are not in current_preset.sounds */}
            {Object.keys(this.state.sounds)
              .filter((sound) => !(sound in this.state.current_preset.sounds))
              .map((sound) => (
                <Form.Check
                  key={sound}
                  type="checkbox"
                  id={sound}
                  label={sound}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.new_sounds.push(sound);
                    } else {
                      this.new_sounds = this.new_sounds.filter(
                        (item) => item !== sound
                      );
                    }
                  }}
                />
              ))}
          </Modal.Body>
          <Modal.Footer>
            <AnalyticsButton
              variant="secondary"
              onClick={() => this.setState({ showAddSoundsModal: false })}
              category="Sound"
              action="Add"
              label="Close"
            >
              Close
            </AnalyticsButton>
            <AnalyticsButton
              variant="primary"
              type="submit"
              onClick={() => this.addSounds()}
              category="Sound"
              action="Add"
              label="Add"
              value={1}
            >
              Add
            </AnalyticsButton>
            {/* Upload new sound button. Open multiple file selector */}
            <AnalyticsButton
              variant="primary"
              onClick={() => {
                document.getElementById("addSoundFiles").click();
              }}
              category="Sound"
              action="Add"
              label="Upload new sound"
              value={1}
            >
              Upload new sound
            </AnalyticsButton>
            <input
              id="addSoundFiles"
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                this.uploadSounds(e.target.files);
              }}
            />
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showDeleteSoundModal}
          onHide={() => this.setState({ showDeleteSoundModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove sound</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this sound?</p>
          </Modal.Body>
          <Modal.Footer>
            <AnalyticsButton
              variant="secondary"
              onClick={() => this.setState({ showDeleteSoundModal: false })}
              category="Sound"
              action="Delete"
              label="Close"
            >
              Close
            </AnalyticsButton>
            <AnalyticsButton
              variant="danger"
              onClick={() => this.deleteSound(this.state.showDeleteSoundModal)}
              category="Sound"
              action="Delete"
              label="Delete"
            >
              Delete
            </AnalyticsButton>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showAddGroupModal}
          onHide={() => this.setState({ showAddGroupModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enter new group name.</p>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.addGroup(document.getElementById("addGroupName").value);
              }}
            >
              <Form.Group controlId="addGroupName">
                <Form.Label>Group name</Form.Label>
                <Form.Control type="text" placeholder="Enter new group name" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <AnalyticsButton
              variant="secondary"
              onClick={() => this.setState({ showAddGroupModal: false })}
              category="Group"
              action="Add"
              label="Close"
            >
              Close
            </AnalyticsButton>
            <AnalyticsButton
              variant="primary"
              type="submit"
              onClick={() =>
                this.addGroup(document.getElementById("addGroupName").value)
              }
              category="Group"
              action="Add"
              label="Add"
              value={1}
            >
              Add
            </AnalyticsButton>
          </Modal.Footer>
        </Modal>
        <Row className="justify-content-md-center">
          {/* Left side of the player, which will contain current preset control panel */}
          {/* Named 'Player' */}
          <Col xs={12} md={6} lg={5}>
            <div id="player">
              {/* current_preset control panel. It will contain preset name, preset volume slider, mute/unmute button, delete preset button, add new sounds button and list of audio files: each with its own volume slider, mute/unmute button and delete button */}
              {/* Div named 'current_preset' */}
              <div id="current_preset">
                <Row style={{ alignItems: "center" }}>
                  {/* Preset name at the top center, updates when preset is changed */}
                  <Col xs={10} md={10} lg={10}>
                    <h1>
                      {
                        this.state.presets[
                          this.presets_manager.current_preset.name
                        ].name
                      }
                    </h1>
                  </Col>
                  {/* Play button */}
                  <Col xs={2} md={2} lg={2}>
                    <ToggleButton
                      variant="outline-primary"
                      checked={this.state.playing}
                      onClick={() =>
                        this.state.playing ? this.pause() : this.play()
                      }
                    >
                      {this.state.playing ? <FaPause /> : <FaPlay />}
                    </ToggleButton>
                  </Col>
                </Row>
                <Row>
                  {/* Main volume slider at the top, mute toggle to the right of it, buttons with icons to change preset name and remove it below slider */}
                  {/* Main volume slider. Make sure it updates current preset volume and that it's value is updated when current preset volume is changed */}
                  <Col xs={10} md={10} lg={10}>
                    <Form>
                      {/*  direction: sideways, so that volume down icon is on the left and volume up icon is on the right */}
                      <Form.Group
                        controlId="formBasicRange"
                        className="d-flex flex-row align-items-center"
                      >
                        {/* Volume down icon */}
                        <FaVolumeDown />
                        <Form.Control
                          type="range"
                          min="0"
                          max="100"
                          value={
                            this.state.presets[
                              this.presets_manager.current_preset.name
                            ].volume
                          }
                          onChange={this.PresetVolumeChange}
                          style={{ margin: "0px 10px 0px 10px" }}
                        />
                        {/* Volume up icon */}
                        <FaVolumeUp />
                      </Form.Group>
                    </Form>
                  </Col>
                  {/* Mute toggle button. Shows mute icon when muted, shows volume icon when not muted. Align top center */}
                  <Col xs={2} md={2} lg={2} className="align-self-center">
                    <ToggleButton
                      variant="outline-primary"
                      checked={
                        this.state.presets[
                          this.presets_manager.current_preset.name
                        ].mute
                      }
                      onClick={() =>
                        this.PresetMuteChange(
                          !this.state.presets[
                            this.presets_manager.current_preset.name
                          ].mute
                        )
                      }
                      onChange={() => {}}
                    >
                      {this.state.presets[
                        this.presets_manager.current_preset.name
                      ].mute ? (
                        <FaVolumeMute />
                      ) : (
                        <FaVolumeUp />
                      )}
                    </ToggleButton>
                  </Col>
                  <Row
                    style={{ margin: "10px 0px 10px 0px" }}
                    className="justify-content-md-center"
                  >
                    {/* Make sure both buttons are the same height */}
                    {/* Change preset name button with pencil icon and text */}
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      style={{ margin: "0px 0px 10px 0px" }}
                    >
                      <AnalyticsButton
                        variant="outline-primary"
                        onClick={() => this.openChangePresetNameModal()}
                        category="Preset"
                        action="Change name"
                        label="Change name"
                        value={1}
                      >
                        <FaPencilAlt />
                        {" Change name"}
                      </AnalyticsButton>
                    </Col>
                    {/* Delete preset button with trash icon and text */}
                    <Col xs={12} md={4} lg={4}>
                      <AnalyticsButton
                        variant="outline-danger"
                        onClick={() => this.openDeletePresetModal()}
                        category="Preset"
                        action="Delete"
                        label="Delete"
                      >
                        <FaTrash />
                        {" Delete preset"}
                      </AnalyticsButton>
                    </Col>
                  </Row>
                </Row>
              </div>
              {/* 4 columns: 1 for preset name, 1 for volume slider, 1 for mute/unmute button and 1 for remove button in a grid. Each row will represent one audio file. */}
              {/* Div named 'Sounds' */}
              <div id="sounds">
                <Row>
                  {/* Maintain vertical allignment of centers of all elements */}
                  <Container fluid className="justify-content-center">
                    {/* List of audio files. Each row will contain audio file name, volume slider, mute/unmute button and delete button */}
                    {Object.keys(
                      this.state.presets[
                        this.presets_manager.current_preset.name
                      ].sounds
                    ).map((sound_name) => (
                      <Row
                        key={sound_name}
                        style={{ margin: "5px 0px 5px 0px" }}
                      >
                        {/* Audio file name */}
                        <Col xs={12} md={4} lg={3} style={{ marginTop: "5px" }}>
                          {/* Keep only name, without extension */}
                          <h5>{sound_name.split(".")[0]}</h5>
                        </Col>
                        {/* Audio file volume slider */}
                        <Col xs={12} md={8} lg={3} style={{ marginTop: "5px" }}>
                          <Form>
                            <Form.Group>
                              <Form.Control
                                type="range"
                                min="0"
                                max="100"
                                value={
                                  this.state.presets[
                                    this.presets_manager.current_preset.name
                                  ].sounds[sound_name].volume
                                }
                                onChange={(e) =>
                                  this.SoundVolumeChange(
                                    sound_name,
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </Form>
                        </Col>
                        {/* Audio file mute/unmute button */}
                        <Col xs={4} md={2} lg={2} style={{ marginTop: "5px" }}>
                          <ToggleButton
                            variant="outline-success"
                            checked={
                              this.state.presets[
                                this.presets_manager.current_preset.name
                              ].sounds[sound_name].mute
                            }
                            value="1"
                            onClick={() =>
                              this.muteSound(
                                sound_name,
                                !this.state.presets[
                                  this.presets_manager.current_preset.name
                                ].sounds[sound_name].mute
                              )
                            }
                          >
                            {this.state.presets[
                              this.presets_manager.current_preset.name
                            ].sounds[sound_name].mute ? (
                              <FaVolumeMute />
                            ) : (
                              <FaVolumeUp />
                            )}
                          </ToggleButton>
                        </Col>
                        {/* Audio file remove button */}
                        <Col xs={8} md={10} lg={4} style={{ marginTop: "5px" }}>
                          <AnalyticsButton
                            variant="outline-danger"
                            onClick={() =>
                              this.openDeleteSoundModal(sound_name)
                            }
                            category="Sound"
                            action="Delete"
                            label="Delete"
                          >
                            <FaTrash />
                            {" Remove sound"}
                          </AnalyticsButton>
                        </Col>
                      </Row>
                    ))}
                  </Container>
                  {/* Add new sounds button, centered */}
                  <div id="add_sounds">
                    <Row className="justify-content-md-center">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AnalyticsButton
                          variant="outline-primary"
                          onClick={() => this.openAddSoundsModal()}
                          style={{
                            maxWidth: "200px",
                            margin: "5px 0px 5px 0px",
                          }}
                          category="Sound"
                          action="Add"
                          label="Add"
                          value={1}
                        >
                          <FaPlus />
                          {" Add sounds"}
                        </AnalyticsButton>
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
          {/* Column with list of presets. Starts with Dark Mode switch in the top right, then column with list of presets, row with forward and backward buttons and row with add preset button. Dark mode switch, column and buttons below have the same width. */}
          <Col sx={12} md={6} lg={3} className="justify-content-md-center">
            <div id="presets_group">
              <Carousel
                className="squard border border-primary rounded"
                activeIndex={Object.keys(this.state.presets_groups).indexOf(
                  this.state.current_group
                )}
                interval={null}
                indicators={false}
                onSelect={(index) => {
                  this.setGroup(Object.keys(this.state.presets_groups)[index]);
                }}
                style={{ padding: "5px 5px 5px 5px" }}
              >
                {Object.keys(this.state.presets_groups).map((group_name) => {
                  return (
                    <Carousel.Item key={group_name}>
                      {/* Current preset group name */}
                      <h3>{group_name}</h3>
                      {/* New group button */}
                      <div id="new_group">
                        <Row>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <AnalyticsButton
                              variant="outline-primary"
                              size="sm"
                              onClick={() => this.openAddGroupModal()}
                              style={{
                                maxWidth: "200px",
                                margin: "5px 0px 15px 0px",
                              }}
                              category="Group"
                              action="Add"
                              label="Add"
                              value={1}
                            >
                              <FaPlus />
                              {" New group"}
                            </AnalyticsButton>
                          </div>
                        </Row>
                      </div>
                      {/* div named 'presets_names_{group_name}' */}
                      <div id={"presets_names_" + group_name}>
                        <Row>
                          {/* Map through presets and display each one id a Button with preset name. Current preset is highlighted. Each button has to be the same width, id small id possible given presets names, but not bigger than set percentage of screen width - lets say 20%. Buttons are in a column. */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* Reverse presets */}
                            {Object.keys(this.state.presets)
                              .reverse()
                              .map((preset_name) => {
                                return (
                                  <AnalyticsButton
                                    key={preset_name}
                                    variant={
                                      preset_name ===
                                      this.state.presets[
                                        this.presets_manager.current_preset.name
                                      ].name
                                        ? "outline-primary"
                                        : "outline-secondary"
                                    }
                                    onClick={() =>
                                      this.changePreset(preset_name)
                                    }
                                    style={{
                                      width: "100%",
                                      maxWidth: "20%",
                                      minWidth: "200px",
                                    }}
                                    category="Preset"
                                    // Include preset name in action
                                    action={"Change to " + preset_name}
                                    label="Change"
                                  >
                                    {preset_name}
                                  </AnalyticsButton>
                                );
                              })}
                          </div>
                        </Row>
                      </div>
                      {/* Backward and forward buttons right next to each other */}
                      <div id={"presets_buttons_" + group_name}>
                        <Row className="d-flex" style={{ margin: "10px 0px" }}>
                          <div className="d-flex justify-content-center">
                            <AnalyticsButton
                              variant="outline-primary"
                              onClick={() => this.prevPreset()}
                              category="Preset"
                              action="Change"
                              label="Change"
                              value={2}
                            >
                              <FaArrowDown style={{ marginRight: "5px" }} />
                              {" Lower"}
                            </AnalyticsButton>
                            <AnalyticsButton
                              variant="outline-primary"
                              onClick={() => this.nextPreset()}
                              category="Preset"
                              action="Change"
                              label="Change"
                              value={2}
                            >
                              {"Raise "}
                              <FaArrowUp style={{ marginLeft: "5px" }} />
                            </AnalyticsButton>
                          </div>
                        </Row>
                      </div>
                      {/* Add preset button */}
                      <Row>
                        <Col>
                          <AnalyticsButton
                            variant="outline-primary"
                            onClick={() => this.openAddPresetModal()}
                            category="Preset"
                            action="Add"
                            label="Add"
                            value={1}
                          >
                            <FaPlus />
                            {" Add new preset"}
                          </AnalyticsButton>
                        </Col>
                      </Row>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  attachAudio() {
    if (!this.state.media_session) {
      this.audio = new Audio();
      this.audio.src = "/silence.wav";
      // loop audio
      this.audio.loop = true;
      this.audio.play().then(this.attachMediaSession());
      this.setState({ media_session: true });
    }
  }

  attachMediaSession() {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: this.presets_manager.current_preset.name,
      artist: "StimulantNoise",
      album: "",
      artwork: [
        // Get some artwork from web
        {
          src: "https://stimulantnoi.se/assets/logo96.png",
          sizes: "96x96",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo128.png",
          sizes: "128x128",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo512.png",
          sizes: "256x256",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo512.png",
          sizes: "384x384",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ],
    });
    if ("mediaSession" in navigator) {
      navigator.mediaSession.setActionHandler("play", () => {
        this.play();
        // set media session state to playing
        this.audio.play();
      });
      navigator.mediaSession.setActionHandler("pause", () => {
        navigator.mediaSession.playbackState = "playing";
        this.pause();
        this.audio.pause();
      });
      navigator.mediaSession.setActionHandler("previoustrack", () => {
        this.prevPreset();
      });
      navigator.mediaSession.setActionHandler("nexttrack", () => {
        this.nextPreset();
      });
      // navigator.mediaSession.playbackState = "playing";
    }
    navigator.mediaSession.setPositionState({
      duration: 0,
      playbackRate: 1.0,
      position: 0,
    });
  }

  updateMediaSession() {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: this.presets_manager.current_preset.name,
      artist: "StimulantNoise",
      album: "",
      artwork: [
        // Get some artwork from web
        {
          src: "https://stimulantnoi.se/assets/logo96.png",
          sizes: "96x96",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo128.png",
          sizes: "128x128",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo512.png",
          sizes: "256x256",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo512.png",
          sizes: "384x384",
          type: "image/png",
        },
        {
          src: "https://stimulantnoi.se/assets/logo512.png",
          sizes: "512x512",
          type: "image/png",
        },
      ],
    });
  }
}

export default Player;
